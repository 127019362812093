<template>
  <UserTop :title="title" @userInfo="getUserInfo">

    <div class="pro-box" v-if="customerServiceInfo">
      <div class="working">工作日时间：09:00~11:30，14:00~17:30</div>
      <div class="working">邮箱：{{customerServiceInfo.email}}</div>
      <div class="wechat-code" v-if="customerServiceInfo && customerServiceInfo.qrCode">
        <generateQRCode :url="customerServiceInfo.qrCode" :image="require('@/assets/images/wx-service.jpg')"></generateQRCode>
      </div>
    </div>

  </UserTop>
</template>
<script>
import CommonApi from "@/api/common"
import UserTop from "@/views/user_promot/components/userTop/index.vue"
import generateQRCode from "@/components/generateQRCode/generateQRCode.vue"
export default {
  name: 'commonProblem',
  components: {
    generateQRCode,
    UserTop
  },
  data() {
    return {
      title: '联系客服',
      userInfo: {
        isLogin: false, // 用户登录状态
        username: '',
        nickname: '',
        token: ''
      },
      customerServiceInfo: ''
    }
  },
  created() {
    this.getCustomerServiceInfo()
  },
  methods: {
    getUserInfo(msg){
      if (msg && msg.user === false){
        this.userInfo = this.$options.data().userInfo
      }else {
        this.userInfo = msg.info
      }
    },
    // 获取客服信息
    getCustomerServiceInfo(){
      CommonApi.getCustomerServiceInfo().then(res => {
        this.customerServiceInfo = res.data
      }).catch(() => {
      })
    }
  }
}
</script>
<style scoped lang="less">
.pro-box {
  width: 100%;
  padding: 30px 12px;
  border-radius: 15px;
  border: 1px solid #ffffff;
  box-shadow: 0 0 15px rgba(81, 59, 248, 0.1);
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .working{
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
    color: #3c3c3c;
  }
  .wechat-code{
    margin-top: 8px;
    text-align: center;
    img{
      display: block;
      margin: auto;
      width: 100px;
    }
  }
}
</style>
